import React from 'react';

import DropdownContext from 'components/bs4/DropdownContext';
import DropdownLink from 'components/bs4/DropdownLink';

import AuthContext from './AuthContext';

function AuthDropdownLink({ to, menu, ...props }) {
  const { isAuth, showAuthModal } = React.useContext(AuthContext);
  const { onClick: showDropdown } = React.useContext(DropdownContext);

  const extra = {};
  if (!isAuth) {
    extra.onClick = (e) => {
      e.preventDefault();
      showAuthModal('login');
      showDropdown(menu);
    };
  }

  return <DropdownLink to={to} {...props} {...extra} />;
}

export default AuthDropdownLink;
