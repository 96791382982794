/*
 * Модель пользователя.
 *
 */
import socket from 'socket';
import Store from 'utils/store';
import { makeGET, makePOST } from 'utils/requests';

const userStore = new Store();
const walletStore = new Store();

// Фунция установки дефолтных значений для пользователя.
function setDefaults(user) {
  user.id = user.id || null;
  user.username = user.username || 'anonymous';
  // user.email = user.email || '';
  // user.full_name = user.full_name || '';
  // user.short_name = user.short_name || '';
  // user.avatar = user.avatar || '';
  return user;
}

// Аутентифицирует пользователя в приложении, обновляя данные в хранилище.
function authenticate(data, cb) {
  walletStore.clean();
  data.is_authenticated = Boolean(data.is_authenticated);
  data.is_ready = true;
  setDefaults(data);
  userStore.set(data);
  userStore.isReady = true;
  const text = data.is_authenticated ? `User #${data.id}` : 'Anonymous';
  console.log(`Auth as ${text}`);
  socket.connect();
  if (cb) cb();
}

/* Обработчики запросов к API. */

// Отправляет запрос получения пользователя с сервера.
// В любом случае запускает приложение.
function auth(runApp) {
  if (userStore.isReady) {
    console.log('User is ready.');
    runApp();
  } else {
    console.log('Check user.');
    makeGET({
      path: '/auth/',
    }).then(({ data, error }) => {
      if (error) {
        authenticate({}, runApp);
      }
      else {
        authenticate(data, runApp);
      }
    });
  }
}

// Отправляет запрос авторизации на сервер.
function login(data, cb) {
  makePOST({
    path: '/auth/login/',
    jsonData: data,
  }).then((response) => {
    if (response.error) {
      if (cb) cb(response);
    }
    else {
      authenticate(response.data, () => {
        if (cb) cb(response);
      });
    }
  });
}

// Отправляет запрос выхода на сервер.
function logout(cb) {
  makeGET({
    path: '/auth/logout/',
  }).then((response) => {
    if (response.error) {
      if (cb) cb(response);
    } else {
      authenticate(response.data, () => {
        if (cb) cb(response);
      });
    }
  });
}

// Отправляет запрос восстановления доступа на сервер.
function restore(data, cb) {
  console.error('Not Implemented');
  if (cb) cb({ error: 'Not Implemented' });
  return;
  /*
  makePOST({
    path: '/auth/password/reset/',
    jsonData: data,
  })
  .then(
    function (response) {
      if (cb) cb(response);
    },
    function (response) {
      if (cb) cb(response);
    }
  );
*/
}

// Отправляет запрос регистрации на сервер.
function registration(data, cb) {
  makePOST({
    path: '/auth/registration/',
    jsonData: data,
  }).then((response) => {
    if (response.error) {
      if (cb) cb(response);
    } else {
      authenticate(response.data, () => {
        if (cb) cb(response);
      });
    }
  });
}

// Отправляет запрос сброса пароля на сервер.
function passwordChange(data, cb) {
  makePOST({
    path: '/auth/password/change/',
    jsonData: data,
  }).then(cb);
}

// Отправляет запрос сброса пароля на сервер.
function passwordReset(data, cb) {
  makePOST({
    path: '/auth/password/reset/',
    jsonData: data,
  }).then(cb);
}

// Отправляет запрос изменения профиля.
function profileChange(data, cb) {
  makePOST({
    path: '/auth/profile/',
    data: data,
  }).then(cb);
}

// Отправляет запрос изменения аватарки профиля.
function avatarChange(file, cb) {
  const data = new FormData();
  data.append('file', file);
  makePOST({
    path: '/auth/profile/avatar/',
    data: data,
  }).then(cb);
}

/* Обработчики ответов от сокета. */

socket.on('users.user_changed', function (data, error) {
  if (error) {
    throw new Error(error);
  }
  const user = data.fields || {};
  user.is_authenticated = Boolean(data.pk);
  setDefaults(user);
  userStore.update(user);
  console.log('User changed.');
});

const user = {
  store: userStore,
  wallet: walletStore,
  auth,
  login,
  logout,
  restore,
  registration,
  passwordChange,
  passwordReset,
  profileChange,
  avatarChange,
  isAuthenticated: function () {
    return userStore.get().is_authenticated;
  },
  isReady: function () {
    return userStore.get().is_ready;
  },
  getID: function () {
    return userStore.get().id;
  },
};

export default user;
