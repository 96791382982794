/*
 * Модель категорий.
 *
 */
import Store from 'utils/store';
import { makeGET } from 'utils/requests';

const store = new Store({
  objects: [],
  isLoaded: false,
  loading: false,
});

function load(force, cb) {
  if (store.get().loading) {
    return;
  }

  if (force || !store.get().isLoaded) {
    store.update({ loading: true });
    makeGET({
      path: '/public/categories/',
    }).then((response) => {
      if (response.error) {
        if (cb) cb(response);
      } else {
        store.update({
          isLoaded: true,
          objects: response.data,
          loading: false,
        });
        if (cb) cb(response);
      }
    });
  }
}

function getCategory(pk) {
  const id = +pk;
  if (!id) return;
  return store.get().objects.find((item) => item.id === id);
}

function getSubCategories(id) {
  return store.get().objects.filter((item) => item.parent_id === id);
}

function getTree(category) {
  const L = [];

  let parent = category;
  while (parent) {
    L.unshift(parent);
    parent = getCategory(parent.parent_id);
  }
  return L;
}

const categories = {
  store,
  load,
  needToGet: () => store.update({ isLoaded: false }),
  getCategory,
  getSubCategories,
  getTree,
};

export default categories;
