/*
 * Подключение сокета к серверу.
 *
 */
let id = 0;

function makeid() {
  if (id > 99999998) id = 0;
  id += 1;
  return id;
}

function Socket() {
  const wsUrl = window.WS_URL || '/ws';
  const handlers = {
    '*': [],
  };

  this._callbacks = {};

  this.on = (signal, callback, extra) => {
    const handler = { callback, extra };
    const _set = handlers[signal];
    if (_set === undefined) {
      handlers[signal] = [handler];
    } else {
      _set.push(handler);
    }
  };

  this.off = (signal, callback) => {
    const _set = handlers[signal];
    if (_set !== undefined) {
      let i = 0;
      while (i < _set.length) {
        if (_set[i].callback === callback) {
          _set.splice(i, 1);
        } else {
          i += 1;
        }
      }
    }
  };

  this.dispatch = (event) => {
    const signal = event['signal'];
    const errors = event.errors;
    const callbackId = `${signal}-${event['id']}`;

    if (signal === 'error') {
      delete this._callbacks[callbackId];
      errors.forEach((error) => this.onerror(error));
      return;
    }

    const textError = errors ? errors.join('; ') : null;

    const callback = this._callbacks[callbackId];
    if (callback) {
      delete this._callbacks[callbackId];
      callback(event.data, textError, signal);
    } else {
      const L = handlers[signal] || [];
      L.push(...(handlers['*'] || []));
      L.forEach((h) => h.callback(event.data, textError, h.extra, signal));
    }
  };

  this.onmessage = (e) => {
    const event = JSON.parse(e.data);
    this.dispatch(event);
  };

  this.onerror = (error) => {
    console.error('Socket error:', error);
  };

  this.onopen = (e, cb) => {
    console.log('Socket is connected!');
    if (cb) cb();
    // При подсоединении выполняем обработчики.
    (handlers['connect'] || []).forEach((h) => h.callback(null, 'connect'));
  };

  this.onclose = (e, cb) => {
    const socket = e.target;

    if (this._socket && this._socket.oid === socket.oid) {
      console.error(`Socket closed unexpectedly. Reconnect after 5 seconds.`);
      // connection closed, discard old websocket and create a new one in 5s
      this._socket = null;
      setTimeout(() => {
        if (!this._socket) {
          console.log('Reconnection attempt.');
          this.connect(cb);
        }
      }, 5000);
    } else {
      console.log('Socket closed by Javascript code.');
    }
    // При отсоединении выполняем обработчики.
    (handlers['disconnect'] || []).forEach((h) =>
      h.callback(null, 'disconnect')
    );
  };

  this.connect = (cb) => {
    if (this._socket) this.disconnect();

    console.log(`Start connection.`);

    const proto = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const url = `${proto}://${window.location.host}${wsUrl}/user/`;
    const socket = new WebSocket(url);

    socket.oid = Math.random().toString(16).slice(2);
    socket.onmessage = this.onmessage;
    socket.onerror = this.onerror;
    socket.onopen = (e) => this.onopen(e, cb);
    socket.onclose = (e) => this.onclose(e, cb);

    this._callbacks = {};
    this._socket = socket;
    // console.log(socket);
  };

  this.disconnect = (cb) => {
    if (this._socket) {
      this._socket.close(1000, `Disconnect by Javascript code.`);
      this._socket = null;
    }
    if (cb) cb();
  };

  this.emit = (signal, data, cb) => {
    const id = makeid();
    this._socket.send(JSON.stringify({ signal, id, data }));
    if (cb) this._callbacks[`${signal}-${id}`] = cb;
  };
}

const socket = new Socket();

export default socket;
