import React from 'react';
import PropTypes from 'prop-types';

import Element from './Element';

export default function ModalClose({ onClose, label = 'Закрыть', ...props }) {
  return (
    <Element
      as="button"
      _className="close"
      onClick={() => onClose()}
      type="button"
      {...props}
    >
      <span aria-hidden="true">×</span>
      <span className="sr-only">{label}</span>
    </Element>
  );
}

ModalClose.propTypes = {
  onClose: PropTypes.func,
  label: PropTypes.string,
};
