/*
 * Class to manage urls.
 */

const CABINET = '/cabinet';
const SERVICES = '/services';
const SUPPORT = '/support';

const urls = {
  HOME: '/',
  SEARCH: '/search',
  ADVERTS: '/adverts',
  ADVERT: '/adverts/:id',
  AUTHORS: '/authors',
  AUTHOR: '/authors/:id',
  SEARCH_REQUESTS: '/search-requests',
  HELP: '/help',
  ABOUT: '/about',
  SECURITY_POLICY: '/security-policy',
  TERMS: '/terms',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGNIN: '/signin',
  CABINET,
  CABINET_ADVERTS: `${CABINET}/adverts`,
  CABINET_ADVERT: `${CABINET}/adverts/:id`,
  CABINET_NEW_ADVERT: `${CABINET}/adverts/new`,
  CABINET_DEALS: `${CABINET}/deals`,
  CABINET_REVIEWS: `${CABINET}/reviews`,
  CABINET_FAVORITES: `${CABINET}/favorites`,
  CABINET_MESSAGES: `${CABINET}/messages`,
  CABINET_MESSAGE: `${CABINET}/messages/:id`,
  CABINET_NOTIFICATIONS: `${CABINET}/notifications`,
  CABINET_SYSTEM_NOTIFICATIONS: `${CABINET}/notifications?type=system`,
  CABINET_SEARCH_REQUESTS: `${CABINET}/search-requests`,
  CABINET_NEW_SEARCH_REQUEST: `${CABINET}/search-requests/new`,
  CABINET_SETTINGS: `${CABINET}/settings`,
  CABINET_SUPPORT: `${CABINET}/support`,
  CABINET_WALLET: `${CABINET}/wallet`,
  SERVICES,
  SERVICES_ADD_ADVERT: `${SERVICES}/add-advert`,
  SERVICES_SEARCH_REQUEST: `${SERVICES}/search-request`,
  SERVICES_SAFE_DEAL: `${SERVICES}/safe-deal`,
  SERVICES_CENTER: `${SERVICES}/center/:id`,
  SERVICES_ADVERTISING_PLACEMENT: `${SERVICES}/advertising-placement`,
  SUPPORT,
  SUPPORT_FEEDBACK: `${SUPPORT}/feedback`,
  SUPPORT_PARTNERSHIP: `${SUPPORT}/partnership`,
  SUPPORT_COMPLAIN: `${SUPPORT}/complain`,
};

export default Object.freeze(urls);
