import React from 'react';
import { Link } from 'react-router-dom';

import Col from 'components/bs4/Col';
import Row from 'components/bs4/Row';
import Icon from 'components/Icon';
import urls from 'urls';

function ListItem({ to, text, ...props }) {
  let children = text;
  if (to) {
    children = (
      <Link to={to} className="text-white text-decoration-none">
        {text}
      </Link>
    );
  }

  return <li {...props}>{children}</li>;
}

function MobileMainMenu() {
  return (
    <>
      <Row className="pt-2">
        {/* Пункты меню */}
        <Col xs={12} sm={6}>
          <ul className="list-unstyled text-white">
            <ListItem text="Главная" to={urls.HOME} />
            <ListItem text="Категории" />
            <ListItem text="-" />
            <ListItem text="Войти" to={urls.LOGIN} />
            <ListItem text="Зарегистрироваться" to={urls.SIGNIN} />
            <ListItem text="-" />
            <ListItem text="Справка" to={urls.HELP} />
          </ul>
        </Col>
        {/* Блок с кнопками */}
        <Col xs={12} sm={6}>
          {/* Ссылка кнопка ведущая на страницу добавления объявления */}
          <div className="p-0 my-1 bg-success rounded">
            <div className="pl-3 pt-2 pb-2 pr-3 d-inline-block rounded-left bg-success-dark text-white">
              <Icon name="plus-circle" className="fs-12" />
            </div>
            <Link
              to={urls.CABINET_NEW_ADVERT}
              className="stretched-link text-white pl-2"
            >
              Добавить объявление
            </Link>
          </div>
          {/* Ссылка кнопка ведущая на страницу добавления запроса на поиск */}
          <div className="p-0 my-1 bg-danger rounded">
            <div className="pl-3 pt-2 pb-2 pr-3 d-inline-block rounded-left bg-danger-dark text-white">
              <Icon name="plus-circle" className="fs-12" />
            </div>
            <Link
              to={urls.CABINET_NEW_SEARCH_REQUEST}
              className="stretched-link text-white pl-2"
            >
              Запрос на поиск
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MobileMainMenu;
