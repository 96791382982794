/*
 * store.js
 *
 * Класс хранилища информации.
 *
 */
let storeSerial = 0;

function Store(initial = {}) {
  // Увеличиваем счётчик идентификаторов экземпляров хранилищ.
  storeSerial++;
  // Присваиваем ID хранилищу.
  this.id = storeSerial;
  // Словарь подписанных компонентов-слушателей.
  const _listeners = {};
  // Счётчик идентификаторов подписанных компонентов-слушателей.
  let instanceSerial = 0;
  // Переменная для хранения данных. Может быть полностью присвоена, поэтому
  // является локальной переменной, а не константой.
  let _data = Object.assign({}, initial);

  // Внутренняя функция. Запускается каждый раз при изменении объекта
  // и вызывает все слушатели-обработчики. Возвращает хранимые данные.
  function emit() {
    for (let id in _listeners) {
      let listener = _listeners[id],
        instance = listener[0],
        key = listener[1],
        callback = listener[2];
      if (typeof callback === 'function') {
        callback(instance, key, _data);
      } else if (key) {
        let state = {},
          type = typeof key;
        // Ключ может указывать что именно нужно взять из хранилища, тогда
        // он содержит 2 значения в списке: ['key_to_state', 'key_from_data'].
        if (type === 'object' && key.length === 2) {
          state[key[0]] = _data[key[1]];
        } else if (type === 'string') {
          state[key] = _data;
        } else {
          throw new Error('Key is not valid.');
        }
        instance.setState(state);
      }
    }
    return _data;
  }

  // Внешняя функция подписки компонента на оповещения об изменении данных.
  // В качестве `instance` передаётся экземпряр React.Component (или что угодно,
  // если есть `callback`, либо есть метод подобный `instance.setState`).
  // Если не передана функция обратного вызова, то в качестве `key`
  // передаётся строка, которая обозначает ключ состояния объекта по которому
  // оно будет обновлено: instance.setState({<key>: data}).
  // А если функция передана, то она будет вызвана так:
  // callback(instance, key, data), поэтому `key` можно указать как угодно.
  this.subscribe = function (instance, key, callback) {
    instanceSerial++;
    // Запоминаем в компоненте ID подписки.
    instance['_store_' + this.id] = instanceSerial;
    _listeners[instanceSerial] = [instance, key, callback];
    return instanceSerial;
  };

  // Внешняя функция отписки компонента от оповещения об изменении.
  this.unSubscribe = function (instance) {
    let id = instance['_store_' + this.id];
    if (id) {
      delete _listeners[id];
    }
  };

  // Внешняя функция для получения хранимых данных.
  this.get = function () {
    return _data;
  };

  // Внешняя функция для замены хранимых данных новыми.
  this.set = function (data) {
    _data = data;
    return emit();
  };

  // Внешняя функция для обновления хранимых данных новыми.
  // Обновляются все ключи первого уровня как есть.
  this.update = function (data = {}) {
    Object.assign(_data, data);
    return emit();
  };

  // Внешняя функция для очистки хранимых данных.
  this.clean = function (data) {
    _data = {};
    return emit();
  };
}

export default Store;
