import './Header.scss';

import React from 'react';

import Container from 'components/bs4/Container';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

/* Верхнее меню */
function Header() {
  return (
    <Container className="bg-primary main-menu">
      {/* для больших экранов */}
      <DesktopHeader />
      {/* для маленьких экранов */}
      <MobileHeader />
    </Container>
  );
}

export default Header;
