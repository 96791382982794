import React from 'react';
import { NavLink } from 'react-router-dom';

import AuthContext from 'components/auth/AuthContext';
import AuthDropdownLink from 'components/auth/AuthDropdownLink';
import AuthLink from 'components/auth/AuthLink';
import Button from 'components/bs4/Button';
import Dropdown from 'components/bs4/Dropdown';
import DropdownContext from 'components/bs4/DropdownContext';
import DropdownLink from 'components/bs4/DropdownLink';
import DropdownDivider from 'components/bs4/DropdownDivider';
import Row from 'components/bs4/Row';
import Col from 'components/bs4/Col';
import NotificationButtons from 'components/NotificationButtons';
import logo from 'images/logo_small.png';
import defaultAvatar from 'images/defaults/profile-default_v_1.png';
import userStore from 'stores/user';
import urls from 'urls';

const PROFILE_MENU_ID = 'desktop-profile-toggle-menu';

function LoginButtons() {
  const { isAuth, user, showAuthModal } = React.useContext(AuthContext);
  const { onClick: showDropdown } = React.useContext(DropdownContext);

  return (
    <>
      {isAuth ? (
        <NotificationButtons />
      ) : (
        <Button
          type="button"
          btn="outline-primary"
          className="rounded-pill d-inline-block mx-1"
          onClick={() => showAuthModal('login')}
        >
          Войти
        </Button>
      )}
      {/*
        Изображение профиля. Для неавторизованных и пользователей
        без аватарки, стандартная картинка. Обращаю внимание, что движок должен
        сам преобразовать размер аватарки под 40х40
      */}
      <Dropdown
        id={PROFILE_MENU_ID}
        className="d-inline-block"
        menuClassName="px-2 mt-1"
        buttonProps={{
          btn: 'inline',
          className: 'mr-1 p-0 position-relative d-block main-menu-icon-link',
          title: 'Профиль',
          children: (
            <>
              <img
                src={user.avatar || defaultAvatar}
                height="40px"
                width="40px"
                className="rounded-circle"
                loading="lazy"
                alt="Профиль"
              />
              {/* Бейдж показывающий онлайн статус зеленый кружек */}
              {isAuth && (
                <span className="badge badge-pill badge-success position-absolute">
                  0
                </span>
              )}
            </>
          ),
        }}
      >
        {/*
          Обращаю внимание, здесь использован класс .mt-1 для задания отступа сверх
          т.к. высота иконок и изображения профиля разные, и всплывающее окошко получается
          сильно прижато к верху
        */}
        <>
          {!isAuth && (
            <AuthDropdownLink to={urls.CABINET} menu={PROFILE_MENU_ID}>
              Войти
            </AuthDropdownLink>
          )}
          <AuthDropdownLink exact to={urls.CABINET} menu={PROFILE_MENU_ID}>
            Личный кабинет
          </AuthDropdownLink>
          <DropdownDivider />
          {isAuth && (
            <>
              <DropdownLink to={urls.CABINET_ADVERTS}>
                Мои объявления
              </DropdownLink>
              <DropdownLink to={urls.CABINET_SEARCH_REQUESTS}>
                Мои запросы
              </DropdownLink>
              <DropdownLink to={urls.CABINET_MESSAGES}>
                Мои сообщения
              </DropdownLink>
              <DropdownLink to={urls.CABINET_DEALS}>Мои сделки</DropdownLink>
              <DropdownDivider />
              <DropdownLink to={urls.CABINET_FAVORITES}>Избранное</DropdownLink>
              <DropdownDivider />
              <DropdownLink to={urls.CABINET_WALLET}>Кошелек</DropdownLink>
              <DropdownDivider />
              <DropdownLink to={urls.CABINET_SYSTEM_NOTIFICATIONS}>
                Системные уведомления
              </DropdownLink>
              <DropdownLink to={urls.CABINET_SUPPORT}>
                Техподдержка
              </DropdownLink>
            </>
          )}
          <DropdownLink to={urls.HELP}>Справка</DropdownLink>
          {isAuth && (
            <>
              <DropdownDivider />
              <Button
                btn="link"
                className="dropdown-item"
                onClick={() => {
                  userStore.logout();
                  showDropdown(null);
                }}
              >
                Выйти
              </Button>
            </>
          )}
        </>
      </Dropdown>

      <AuthLink
        to={urls.CABINET_NEW_ADVERT}
        className="btn btn-danger py-1 py-lg-2 px-md-2 px-lg-3"
      >
        Подать объявление
      </AuthLink>
    </>
  );
}

function DesktopHeader() {
  return (
    <Row className="d-none d-md-flex my-3">
      {/* Лого сайта */}
      <Col xs={4} className="pl-0">
        <NavLink to={urls.HOME} className="navbar-brand mr-0">
          <img src={logo} alt="ProdaiZIP.ru" loading="lazy" />
        </NavLink>
      </Col>

      {/* Блок с кнопками меню */}
      <Col xs={8} className="text-right">
        <LoginButtons />
      </Col>
    </Row>
  );
}

export default DesktopHeader;
