import React from 'react';
import { debounce } from 'lodash-es';

import ModalClose from 'components/bs4/ModalClose';
import FormInput from 'components/bs4/FormInput';
import DropdownContext from 'components/bs4/DropdownContext';
import DropdownMenu from 'components/bs4/DropdownMenu';
import { makeid } from 'components/bs4/utils';
import { makeGET } from 'utils/requests';

function DropdownItem({ onClick, children, ...props }) {
  return (
    <a href="#click" className="dropdown-item" {...props} onClick={onClick}>
      {children}
    </a>
  );
}

class LocationSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.labelRef = React.createRef();
    this.state = {
      objects: [],
      number: 0,
      limit: 10,
      count: 0,
      pages: 0,
      query: '',
      selected: null,
      id: makeid('location-selector'),
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (value) this.loadLocation(value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value && value !== prevProps.value) {
      this.loadLocation(value);
    }
  }

  loadLocation = (id) => {
    makeGET({
      path: `/public/locations/${id}/`,
    }).then(({ data, error }) => {
      if (error) {
        console.error(error);
        this.setState({ selected: null });
      } else {
        this.setState({ selected: data });
      }
    });
  };

  findLocations = debounce((q) => {
    const { limit } = this.state;
    const filters = this.props.filters || {};
    makeGET({
      path: '/public/locations/',
      search: { q, l: limit, ...filters },
    }).then(({ data, error }) => {
      if (!error) {
        this.setState({ ...data });
      }
    });
  }, 500);

  changeQuery = (query) => {
    this.setState({ query, selected: null }, this.findLocations(query));
  };

  selectLocation = (selected) => {
    this.setState({ selected }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(selected ? selected.id : null);
    });
  };

  getText = (item) => {
    if (this.props.short) {
      return item.name;
    }
    return item.path.replace(/::/g, '/');
  };

  render() {
    const { objects, query, selected, id } = this.state;
    const { name, value, hideInputMode, ...props } = this.props;

    if (hideInputMode && selected && value) {
      return (
        <p>
          <span>{selected.path.replace(/::/g, '/')}</span>
          <ModalClose onClose={() => this.selectLocation(null)} />
        </p>
      );
    }

    const text = selected ? selected.name : query;

    return (
      <>
        {!!selected && <input type="hidden" name={name} value={selected.id} />}
        <FormInput
          {...props}
          id={id}
          value={text}
          onChange={(v) => {
            this.changeQuery(v);
            if (this.context.current !== id) this.context.onClick(id);
          }}
          autoComplete="off"
          labelRef={this.labelRef}
          elementRef={this.inputRef}
        />
        {!!objects.length && (
          <DropdownMenu
            controlRef={this.inputRef}
            className="px-2"
            correction={[
              5,
              this.labelRef.current ? this.labelRef.current.offsetHeight : 0,
            ]}
          >
            {objects.map((item) => (
              <DropdownItem
                key={item.id}
                onClick={(e) => {
                  this.selectLocation(item);
                  this.context.onClick(null);
                  e.preventDefault();
                }}
              >
                {this.getText(item)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </>
    );
  }
}

LocationSelector.contextType = DropdownContext;
LocationSelector.defaultProps = {
  // placeholder: 'Местоположение',
};

export default LocationSelector;
