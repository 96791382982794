import React from 'react';
import { NavLink } from 'react-router-dom';

import AuthLink from 'components/auth/AuthLink';
import Col from 'components/bs4/Col';
import Row from 'components/bs4/Row';
import Icon from 'components/Icon';
import urls from 'urls';

/* Форма Быстрого поиска */
function MobileSearchRequestForm() {
  return (
    <Row className="bg-primary rounded py-2 my-3">
      <Col xs={12} md={4} className="mb-3 mb-md-0 text-white">
        <h5 className="mb-0 text-white">
          <NavLink
            to={urls.SEARCH_REQUESTS}
            className="text-white text-decoration-none"
          >
            <Icon name="binoculars" className="mr-2" />
            <span>Запросы на поиск</span>
          </NavLink>
        </h5>
      </Col>

      <Col xs={12} md={4} className="mb-3 mb-md-0 text-white">
        <NavLink
          to={urls.SEARCH_REQUESTS}
          className="btn btn-outline-light py-0 w-100"
        >
          Смотреть что ищут
        </NavLink>
      </Col>

      <Col xs={12} md={4} className="mb-3 mb-md-0 text-white">
        <AuthLink
          to={urls.CABINET_NEW_SEARCH_REQUEST}
          className="btn btn-outline-light py-0 w-100"
        >
          Подать запрос
        </AuthLink>
      </Col>
    </Row>
  );
}

export default MobileSearchRequestForm;
