export function layoutName(name, allowed = ['xs', 'sm', 'md', 'lg', 'xl']) {
  return allowed.includes(name) ? name : null;
}

export const layoutValuesList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
]


export function layoutValue(value) {
  const int = parseInt(value);
  return int && int >= 1 && int <= 12 && int.toString() === value.toString()
    ? int
    : null;
}

const _idgroups = {};

export function makeid(group = 'object') {
  let id = _idgroups[group] || 0;
  id += 1;
  _idgroups[group] = id;
  return `bs4-${group}-${id}`;
}

/* Расфокусировка вешается на onFocus компонента. */
export function defocus(time = 0) {
  return (e) => {
    if (time) {
      setTimeout(() => e.target.blur(), time);
    } else {
      e.target.blur();
    }
  };
}
