import React from 'react';

import userStore from 'stores/user';

import AuthContext from './AuthContext';

class AuthManager extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        user: {},
        isAuth: false,
        authModalMode: null,
        showAuthModal: this.showModal,
        next: '',
      },
    };
  }

  syncStore = () => {
    const user = userStore.store.get();
    const oldValue = this.state.value;
    const value = {
      ...oldValue,
      user,
      isAuth: user.is_authenticated,
    };
    this.setState({ value });
  };

  componentDidMount() {
    userStore.store.subscribe(this, null, this.syncStore);
    this.syncStore();
  }

  componentWillUnmount() {
    userStore.store.unSubscribe(this);
  }

  showModal = (authModalMode = 'login', next) => {
    const oldValue = this.state.value;
    if (authModalMode === oldValue.authModalMode) {
      authModalMode = null;
    }
    this.setState({
      value: {
        ...oldValue,
        authModalMode,
        next: next !== undefined ? next : oldValue.next,
      },
    });
  };

  render() {
    return <AuthContext.Provider value={this.state.value} {...this.props} />;
  }
}

export default AuthManager;
