import React from 'react';
import PropTypes from 'prop-types';

import FormControl from './FormControl';

function FormSelect({ options, valueKey, textKey, classKey, empty, ...props }) {
  const getValue = typeof valueKey === 'string' ? (i) => i[valueKey] : valueKey;
  const getText = typeof textKey === 'string' ? (i) => i[textKey] : textKey;
  const getClass = typeof classKey === 'string' ? (i) => i[classKey] : classKey;

  const children = (
    <>
      {empty && <option value="">{empty === true ? '' : empty}</option>}
      {options.map((item, key) => {
        return (
          <option key={key} value={getValue(item)} className={getClass(item)}>
            {getText(item)}
          </option>
        );
      })}
    </>
  );

  return (
    <FormControl
      idGroup="select"
      type="select"
      children={children}
      {...props}
    />
  );
}

FormSelect.propTypes = {
  empty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.array.isRequired,
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  textKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  classKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

FormSelect.defaultProps = {
  valueKey: 'id',
  textKey: 'name',
  classKey: function () {
    return '';
  },
};

export default FormSelect;
