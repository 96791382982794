import React from 'react';
import PropTypes from 'prop-types';

import Element from './Element';
import { defocus } from './utils';

export default function Button({
  as,
  btn,
  sm,
  lg,
  block,
  size,
  focus = 200,
  active,
  ...props
}) {
  let cls = `btn btn-${btn}`;

  if (size === 'lg' || size === 'sm') {
    cls += ` btn-${size}`;
  } else if (lg) {
    cls += ' btn-lg';
  } else if (sm) {
    cls += ' btn-sm';
  }

  if (block) {
    cls += ' btn-block';
  }

  if (active) {
    cls += ' active';
  }

  let tag = 'button';
  if (as === 'a') {
    tag = as;
    if (!props.href) {
      props.href = '#click';
    }

    const onClick = props.onClick;
    props.onClick = (e) => {
      e.preventDefault();
      if (onClick) onClick();
    };
  }

  return (
    <Element as={tag} _className={cls} onFocus={defocus(focus)} {...props} />
  );
}

Button.propTypes = {
  as: PropTypes.string,
  href: PropTypes.string,
  btn: PropTypes.string,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  onClick: PropTypes.func,
  focus: PropTypes.string,
};
