import React from 'react';
import PropTypes from 'prop-types';

import Element from './Element';
import { layoutName, layoutValue } from './utils';

export default function Row({ as = 'div', form, cols, ...props }) {
  let cls = form ? 'form-row' : 'row';

  if (cols && !form) {
    Object.entries(cols).forEach(([name, value]) => {
      if (layoutName(name) && layoutValue(value)) {
        if (name === 'xs') {
          cls += ` row-cols-${value}`;
        } else {
          cls += ` row-cols-${name}-${value}`;
        }
      }
    });
  }

  return <Element as={as} _className={cls} {...props} />;
}

Row.propTypes = {
  as: PropTypes.string,
  form: PropTypes.bool,
  cols: PropTypes.object,
};
