import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Element from './Element';
import Button from './Button';
import DropdownContext from './DropdownContext';
import DropdownMenu from './DropdownMenu';

function DropdownButton({
  onClick,
  children,
  text = 'Dropdown button',
  ...props
}) {
  return (
    <Button onClick={onClick} {...props}>
      {children || text}
    </Button>
  );
}

function Dropdown({
  as = 'div',
  id,
  buttonProps,
  children,
  menuClassName = '',
  ...props
}) {
  if (!buttonProps) {
    buttonProps = {
      btn: 'secondary',
      className: 'dropdown-toggle',
    };
  }

  const ref = useRef();
  const { onClick } = React.useContext(DropdownContext);

  return (
    <Element as={as} id={id} _className="dropdown" {...props} elementRef={ref}>
      <DropdownButton
        onClick={(event) => {
          event.preventDefault();
          onClick(id);
        }}
        {...buttonProps}
      />
      <DropdownMenu control={id} controlRef={ref} className={menuClassName}>
        {children}
      </DropdownMenu>
    </Element>
  );
}

Dropdown.propTypes = {
  as: PropTypes.string,
  id: PropTypes.string.isRequired,
  buttonProps: PropTypes.object,
  children: PropTypes.element.isRequired,
  menuClassName: PropTypes.string,
};

export default Dropdown;
