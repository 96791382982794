import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import DropdownContext from './DropdownContext';

function DropdownLink({
  to,
  children,
  className = 'dropdown-item',
  onClick,
  ...props
}) {
  const context = useContext(DropdownContext);

  return (
    <NavLink
      to={to}
      className={className}
      {...props}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        context.onClick(null);
      }}
    >
      {children}
    </NavLink>
  );
}

export default DropdownLink;
