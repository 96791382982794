/*
 * Модель уведомлений по доске.
 *
 */
import socket from 'socket';
import Store from 'utils/store';

import user from './user';

const store = new Store({
  system: [],
  deals: [],
  chats: [],
});

function addNoticeToList(data) {
  const list = [
    data,
    ...store.get()[data.category].filter((item) => item.id !== data.id),
  ];
  store.update({ [data.category]: list });
}

function removeNoticeFromList({ id, category }) {
  const list = store.get()[category].filter((item) => item.id !== id);
  store.update({ [category]: list });
}

socket.on(
  'board.get_notices',
  function ({ system = [], deals = [], chats = [] }) {
    store.update({ system, deals, chats });
  }
);

socket.on('board.notice_changed', function (data) {
  if (!data.is_hidden) {
    addNoticeToList(data);
  } else {
    removeNoticeFromList(data);
  }
});

socket.on('board.notice_removed', function (data) {
  removeNoticeFromList(data);
});

socket.on('connect', function () {
  if (user.isAuthenticated) socket.emit('board.get_notices');
});

function hideNotice(id) {
  socket.emit('board.hide_notice', { id });
}

function removeNotice(id) {
  socket.emit('board.remove_notice', { id });
}

const talks = {
  store,
  hideNotice,
  removeNotice,
};

export default talks;
