import React from 'react';
import PropTypes from 'prop-types';

import Element from './Element';
import { layoutValue, layoutValuesList } from './utils';

export default function Col({ as = 'div', xs = 12, sm, md, lg, xl, form, ...props }) {
  let cls = form ? 'form-group col' : 'col';
  if (layoutValue(xs)) {
    cls += `-${xs}`;
  }
  if (layoutValue(sm)) {
    cls += ` col-sm-${sm}`;
  }
  if (layoutValue(md)) {
    cls += ` col-md-${md}`;
  }
  if (layoutValue(lg)) {
    cls += ` col-lg-${lg}`;
  }
  if (layoutValue(xl)) {
    cls += ` col-xl-${xl}`;
  }

  return <Element as={as} _className={cls} {...props} />;
}

const colValues = PropTypes.oneOf(layoutValuesList);

Col.propTypes = {
  as: PropTypes.string,
  xs: colValues,
  sm: colValues,
  md: colValues,
  lg: colValues,
  xl: colValues,
  form: PropTypes.bool,
};
