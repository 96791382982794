/*
 * requests.js
 *
 * Функции для работы с HTTP-запросами.
 *
 */
import queryString from 'query-string';
const BASE_URL = window.API_URL || '/api';

// Поиск значения куки.
export function getCookie(name) {
  const cookie = document.cookie,
    key = name + '=',
    edge = key.length;
  let value = null;
  if (cookie && cookie !== '') {
    const cookies = cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, edge) === key) {
        value = decodeURIComponent(cookie.substring(edge));
        break;
      }
    }
  }
  return value;
}

function parseData(xhr) {
  const header = xhr.getResponseHeader('Content-Type');
  if (header && header.startsWith('application/json')) {
    return JSON.parse(xhr.responseText);
  }
  return xhr.responseText;
}

// Запрос общего плана. По-умолчанию - GET.
export function makeRequest({
  method = 'GET',
  url = BASE_URL,
  data,
  contentType,
}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const handleRequest = () => {
      resolve({
        data: parseData(xhr),
        status: xhr.status,
        error: xhr.status >= 400 ? xhr.statusText : null,
        xhr: xhr,
      });
    };

    xhr.open(method, url);
    // Затем для уже открытого XMLHttpRequest ставим заголовки.
    // Не передавайте заголовок 'multipart/form-data' или
    // 'application/x-www-form-urlencoded' они должны проставится автоматически
    // когда анализируется FormData.
    if (contentType) {
      xhr.setRequestHeader('Content-Type', contentType);
    }
    if (!/^(GET|HEAD|OPTIONS|TRACE)$/.test(method.toUpperCase())) {
      xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
    }

    // Успех или ошибка на сервере.
    xhr.onload = handleRequest;
    // Сетевая ошибка.
    xhr.onerror = handleRequest;
    xhr.send(data);
  });
}

function makeUrl(baseUrl, path, search) {
  const query = queryString.stringify(search);
  return query ? `${baseUrl}${path}?${query}` : `${baseUrl}${path}`;
}

// Обычный GET с параметрами.
export function makeGET({ path, search, contentType, baseUrl = BASE_URL }) {
  return makeRequest({
    url: makeUrl(baseUrl, path, search),
    contentType: contentType,
  });
}

// POST.
export function makePOST({
  path,
  search,
  data,
  jsonData,
  contentType,
  baseUrl = BASE_URL,
}) {
  if (jsonData) {
    data = JSON.stringify(jsonData);
    contentType = 'application/json';
  }
  return makeRequest({
    method: 'POST',
    url: makeUrl(baseUrl, path, search),
    data: data,
    contentType: contentType,
  });
}

// DELETE.
export function makeDELETE({ path, search, contentType, baseUrl = BASE_URL }) {
  return makeRequest({
    method: 'DELETE',
    url: makeUrl(baseUrl, path, search),
    contentType: contentType,
  });
}

// только для DEBUG.
if (process.env.NODE_ENV === 'development') {
  window.requests = { getCookie, makeRequest, makeGET, makePOST, makeDELETE };
}
