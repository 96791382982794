import './css/bootstrap.scss';
import './css/fonts.scss';
import './css/global.css';
import './css/category.css';
import './css/ads_views.css';
import './css/messages.css';

import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AuthManager from 'components/auth/AuthManager';
import Container from 'components/bs4/Container';
import DropdownManager from 'components/bs4/DropdownManager';
import Header from 'components/Header';
import PageLazyLoading from 'components/PageLazyLoading';
import ScrollToTop from 'components/ScrollToTop';
import urls from 'urls';

// Lazy imports:

const AuthModal = React.lazy(() => import('components/auth/AuthModal'));
const Footer = React.lazy(() => import('components/Footer'));

const Advert = React.lazy(() => import('pages/Advert'));
const Help = React.lazy(() => import('pages/Help'));
const Home = React.lazy(() => import('pages/Home'));
const Page404 = React.lazy(() => import('pages/Page404'));
const Search = React.lazy(() => import('pages/Search'));
const Cabinet = React.lazy(() => import('pages/Cabinet'));

function App() {
  return (
    <DropdownManager>
      <AuthManager>
        <BrowserRouter basename={window.APP_URL}>
          <ScrollToTop />
          <Header />
          <Container as="main">
            <Suspense fallback={<PageLazyLoading />}>
              <Switch>
                <Route path={urls.CABINET} component={Cabinet} />
                <Route path={urls.ADVERT} component={Advert} />
                <Route path={urls.HELP} component={Help} />
                <Route path={urls.SEARCH} component={Search} />
                <Route path={urls.HOME} exact component={Home} />
                <Route path="*" component={Page404} />
              </Switch>
            </Suspense>
          </Container>
          <Suspense fallback={<></>}>
            <Footer />
          </Suspense>
          <Suspense fallback={<></>}>
            <AuthModal />
          </Suspense>
        </BrowserRouter>
      </AuthManager>
    </DropdownManager>
  );
}

export default App;
