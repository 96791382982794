import React from 'react';

import DropdownContext from './DropdownContext';

class DropdownManager extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        current: null,
        onClick: this.onDropdownClick,
      },
    };
  }

  componentDidMount() {
    document.body.addEventListener('mousedown', this.closeDropdown);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.closeDropdown);
  }

  onDropdownClick = (current) => {
    const oldValue = this.state.value;
    if (current === oldValue.current) {
      current = null;
    }
    this.setState({ value: { ...oldValue, current } });
  };

  closeDropdown = (event) => {
    if (this.state.value.current) this.onDropdownClick(null);
  };

  render() {
    return (
      <DropdownContext.Provider value={this.state.value} {...this.props} />
    );
  }
}

export default DropdownManager;
