/*
 * Кнопки количества новых уведомлений.
 *
 */
import moment from 'moment';
import React from 'react';

import Button from 'components/bs4/Button';
import Col from 'components/bs4/Col';
import Dropdown from 'components/bs4/Dropdown';
import DropdownLink from 'components/bs4/DropdownLink';
import DropdownDivider from 'components/bs4/DropdownDivider';
import Row from 'components/bs4/Row';
import Icon from 'components/Icon';
import defaultPreview from 'images/defaults/nophoto.png';
import noticesStore from 'stores/notices';
import urls from 'urls';

function ButtonClose({ id }) {
  return (
    <Button
      type="button"
      btn="inline"
      sm
      className="text-decoration-none p-0 chat-thumb-delete"
      onClick={() => noticesStore.hideNotice(id)}
    >
      <Icon name="close" />
    </Button>
  );
}

/* Сообщение системного уведомления. */
function SystemNotify({ id, body, color, support }) {
  let url;
  if (support) {
    url = `${urls.CABINET_SUPPORT}/${support.ticket}#msg${support.message}`;
  } else {
    url = `${urls.CABINET_NOTIFICATIONS}/${id}`;
  }

  return (
    <div className={`alert alert-${color || 'primary'}`}>
      <Row>
        <Col xs="12">
          <DropdownLink
            to={url}
            className="text-decoration-none text-body stretched-link"
          >
            {body}
          </DropdownLink>
        </Col>

        <Col xs="12" className="text-right">
          {/* Кнопка удаления уведомления */}
          <div className="chat-thumb-delete">
            <ButtonClose id={id} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function AdvertNotify({
  // notice fields:
  id,
  updated,
  body,
  // extra fields:
  url,
  author,
  subject,
  price,
  preview,
  link_color = 'primary',
  author_color = 'secordary',
}) {
  return (
    <div className="mt-2 mb-2 rounded chat-thumb">
      <Row>
        {/*
          Блок изображения
          Обращаю внимание, движок сам преобразовывает размер под 50х50.
        */}
        <Col xs="3">
          <DropdownLink to={url} className="">
            <img
              className="rounded img-fluid"
              src={preview || defaultPreview}
              alt={subject}
            />
          </DropdownLink>
        </Col>
        {/* Блок инфо сообщения о сделке */}
        <Col xs="6" className="pl-0">
          {/* Наименование объявления, по которому пришел запрос на сделку */}
          <div className="chat-thumb-caption">{subject}</div>
          {/* Обращаю внимание, здесь указывается имя пользователя, отправившего запрос на сделку */}
          <div className={`text-${author_color} chat-thumb-seller-name`}>
            {author}
          </div>
          {/* Подпись */}
          <div className={`text-${link_color} chat-thumb-message`}>
            {/* 'Хочет купить' или 'Хочет продать' или '2 новых сообщения' */}
            <DropdownLink
              to={url}
              className="stretched-link text-decoration-none"
            >
              {body}
            </DropdownLink>
          </div>
        </Col>
        {/* Блок с ценой объявления, даты или времени подачи сделки и кнопки удаления уведомления */}
        <div className="col-3 pl-0 text-right">
          <div className="chat-thumb-price">{price} ₽</div>
          <div className="text-secondary h6 mb-0">
            {moment(updated).fromNow()}
          </div>
          {/* Кнопка удаления уведомления */}
          <div className="chat-thumb-delete">
            <ButtonClose id={id} />
          </div>
        </div>
      </Row>
    </div>
  );
}

/* Сообщение уведомления по сделке. Ссылка ведет на страницу сделки. */
function DealNotify({ deal_id, ...props }) {
  const url = `${urls.CABINET_DEALS}/${deal_id}`;

  return <AdvertNotify {...props} url={url} />;
}

/* Сообщение уведомления по сделке. Ссылка ведет на страницу сделки. */
function ChatNotify({ chat_id, ...props }) {
  const url = `${urls.CABINET_MESSAGES}/${chat_id}`;
  return <AdvertNotify {...props} url={url} />;
}

/* Бейдж показывающий количество новых сообщений */
function CountBadge({ value }) {
  if (!value) return <></>;
  return (
    <span className="badge badge-pill badge-danger py-1 position-absolute">
      {value}
    </span>
  );
}

function NotifyMap({ objects, Notify }) {
  if (!objects.length) {
    return (
      <div className="alert text-center text-secondary">
        Новых уведомлений нет
      </div>
    );
  }

  return (
    <>
      {objects.map((item) => (
        <Notify {...item} key={item.id} />
      ))}
    </>
  );
}

class NotificationButtons extends React.PureComponent {
  state = {
    system: [],
    deals: [],
    chats: [],
  };

  syncStore = () => {
    const { system, deals, chats } = noticesStore.store.get();
    this.setState({ system, deals, chats });
  };

  componentDidMount() {
    noticesStore.store.subscribe(this, null, this.syncStore);
    this.syncStore();
  }

  componentWillUnmount() {
    noticesStore.store.unSubscribe(this);
  }

  render() {
    const { system, deals, chats } = this.state;

    return (
      <>
        {/* Кнопка количества новых системных уведомлений */}
        <Dropdown
          id="system-notification-dropdown"
          className="d-inline-block"
          menuClassName="px-2"
          buttonProps={{
            btn: 'inline',
            className: 'mr-1 p-0 position-relative d-block main-menu-icon-link',
            title: 'Системные уведомления',
            children: (
              <>
                <Icon name="stack" className="fs-16">
                  <Icon name="circle" stack="2" className="text-light" />
                  <Icon name="bell" stack="1" className="text-secondary" />
                </Icon>
                <CountBadge value={system.length} />
              </>
            ),
          }}
        >
          {/* Раскрывающийся список новых системных уведомлений */}
          <>
            <NotifyMap objects={system} Notify={SystemNotify} />
            <DropdownDivider />
            {/* Ссылка перехода на все системные уведомления */}
            <DropdownLink to={urls.CABINET_SYSTEM_NOTIFICATIONS}>
              Все уведомления
            </DropdownLink>
          </>
        </Dropdown>

        {/* Кнопка количества новых сделок */}
        <Dropdown
          id="deals-notification-dropdown"
          className="d-inline-block"
          menuClassName="px-2"
          buttonProps={{
            btn: 'inline',
            className: 'mr-1 p-0 position-relative d-block main-menu-icon-link',
            title: 'Сделки',
            children: (
              <>
                <Icon name="stack" className="fs-16">
                  <Icon name="circle" stack="2" className="text-light" />
                  <Icon name="list" stack="1" className="text-secondary" />
                </Icon>
                <CountBadge value={deals.length} />
              </>
            ),
          }}
        >
          {/* Раскрывающийся список новых предложений о сделке */}
          <>
            <NotifyMap objects={deals} Notify={DealNotify} />
            <DropdownDivider />
            {/* Ссылка перехода на все сделки */}
            <DropdownLink to={urls.CABINET_DEALS}>Все сделки</DropdownLink>
          </>
        </Dropdown>

        {/* Кнопка количества новых сообщений */}
        <Dropdown
          id="chats-notification-dropdown"
          className="d-inline-block"
          menuClassName="px-2"
          buttonProps={{
            btn: 'inline',
            className: 'mr-1 p-0 position-relative d-block main-menu-icon-link',
            title: 'Сообщения',
            children: (
              <>
                <Icon name="stack" className="fs-16">
                  <Icon name="circle" stack="2" className="text-light" />
                  <Icon name="comment" stack="1" className="text-secondary" />
                </Icon>
                <CountBadge value={chats.length} />
              </>
            ),
          }}
        >
          {/* Раскрывающийся список новых системных уведомлений */}
          <>
            <NotifyMap objects={chats} Notify={ChatNotify} />
            <DropdownDivider />
            {/* Ссылка перехода на все сообщения */}
            <DropdownLink to={urls.CABINET_MESSAGES}>
              Все сообщения
            </DropdownLink>
          </>
        </Dropdown>
      </>
    );
  }
}

export default NotificationButtons;
