import React from 'react';

function Icon({
  children,
  name,
  fixed,
  size,
  stack,
  spin,
  pulse,
  rotate,
  flip,
  inverse,
  align,
  className,
  ...props
}) {
  let cls = `fa fa-${name}`;
  if (fixed) cls += ' fa-fw';
  if (size) cls += ` fa-${size}x`;
  if (stack) cls += ` fa-stack-${stack}x`;
  if (spin) cls += ' fa-spin';
  if (pulse) cls += ' fa-pulse';
  if (rotate) cls += ` fa-rotate-${rotate}`;
  if (flip) cls += ` fa-flip-${flip}`;
  if (align) cls += ` fa-align-${align}`;
  if (inverse) cls += ' fa-inverse';
  if (className) cls += ` ${className}`;

  return (
    <span className={cls} {...props}>
      {children}
    </span>
  );
}

export default Icon;
