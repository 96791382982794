import React from 'react';

import Button from 'components/bs4/Button';
import Col from 'components/bs4/Col';
import Form from 'components/bs4/Form';
import FormSelect from 'components/bs4/FormSelect';
import FormInput from 'components/bs4/FormInput';
import Row from 'components/bs4/Row';
import LocationSelector from 'components/LocationSelector';
import categoriesStore from 'stores/categories';

function ThinForm({ query, categories, onSubmit, forMobile }) {
  const [category, setCategory] = React.useState(
    categoriesStore.getCategory(query.category) || null
  );

  function getHidden() {
    const hidden = [];
    const selfFields = ['location', 'category', 'q'];
    const parameters = category ? category.parameters : [];

    function checkParam(name) {
      // ключи параметров имеют вид `p1`, `p199` и т.д.
      let param = name.match(/^p(\d+)$/);
      return !!(param && param.length && !parameters.includes(+param[1]));
    }

    for (let name in query) {
      if (checkParam(name)) {
        // пропускаем параметр, который не входит в эту группу.
      } else if (selfFields.indexOf(name) < 0) {
        hidden.push({ name, value: query[name] });
      }
    }
    return hidden;
  }

  const rowProps = {
    form: true,
    className: forMobile
      ? 'bg-primary rounded'
      : 'd-none d-md-flex bg-primary rounded',
  };
  const selectColProps = {
    xs: '12',
    md: '6',
    lg: '3',
    className: forMobile
      ? 'mb-2 mb-lg-0'
      : 'px-1 mb-2 mb-lg-0 order-md-1 order-lg-0',
  };
  const queryColProps = {
    xs: '12',
    md: '12',
    lg: '6',
    className: forMobile
      ? 'mb-2 mb-lg-0'
      : 'px-1 mb-2 mb-lg-0 order-md-0 order-lg-1',
  };
  const locationColProps = {
    xs: '12',
    md: '6',
    lg: '2',
    className: forMobile ? 'mb-2 mb-lg-0' : 'px-1 mb-2 mb-lg-0 order-md-2',
  };
  const submitColProps = {
    xs: '12',
    md: '12',
    lg: '1',
    className: forMobile ? '' : 'px-1 order-md-3',
  };
  const submitButtonProps = {
    btn: 'warning',
    className: forMobile ? 'w-100 mt-2' : 'w-100',
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        {getHidden().map(({ name, value }, key) => (
          <input key={key} type="hidden" name={name} value={value} />
        ))}
        <Row {...rowProps}>
          <Col {...selectColProps}>
            {/* Обращаю внимание, для главных категорий используется выделение классами .bg-primary .text-white */}
            <FormSelect
              name="category"
              empty="Все категории"
              value={query.category}
              onChange={(category) =>
                setCategory(categoriesStore.getCategory(category) || null)
              }
              options={categories}
              textKey={(item) => '- '.repeat(item.level) + item.name}
              classKey={(item) => (item.level ? '' : 'bg-primary text-white')}
            />
          </Col>
          <Col {...queryColProps}>
            <FormInput name="q" placeholder="Что вы ищете" value={query.q} />
          </Col>
          <Col {...locationColProps}>
            <LocationSelector
              name="location"
              placeholder="Местоположение"
              value={query.location}
            />
          </Col>
          <Col {...submitColProps}>
            <Button type="submit" {...submitButtonProps}>
              Поиск
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ThinForm;
