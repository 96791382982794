import React from 'react';
import PropTypes from 'prop-types';

export default function Element({
  as = 'div',
  className = '',
  _className = '',
  children,
  elementRef,
  ...props
}) {
  props.className = `${_className} ${className}`;
  props.ref = elementRef;

  return React.createElement(as, props, children);
}

Element.propTypes = {
  as: PropTypes.string,
  _className: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
