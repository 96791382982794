import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/bs4/Button';
import Col from 'components/bs4/Col';
import Collapse from 'components/bs4/Collapse';
import Row from 'components/bs4/Row';
import Icon from 'components/Icon';

import MobileMainMenu from './MobileMainMenu';
import MobileSearchForm from './MobileSearchForm';
import MobileSearchRequestForm from './MobileSearchRequestForm';

function MobileHeader({ setVisibleLogin }) {
  const [visibleMenu, setVisibleMenu] = React.useState(null);

  function toggleVisibleMenu(name) {
    setVisibleMenu(name !== visibleMenu ? name : null);
  }

  return (
    <Row className="d-md-none pt-1 pb-2">
      {/* Кнопка меню, она же название сервиса */}
      <Col xs={3}>
        <Button
          btn="inline"
          className="position-relative main-menu-icon-link py-0"
          onClick={() => toggleVisibleMenu('main')}
        >
          <h4 className="dropdown-toggle text-white">ProdaiZIP</h4>
        </Button>
      </Col>

      {/* Блок со ссылками кнопками */}
      <Col xs={9} className="text-right">
        {/* Кнопка раскрывающегося списка Запросов на поиск */}
        <Button
          btn="inline"
          onClick={() => toggleVisibleMenu('searchRequest')}
          className="position-relative py-0"
        >
          <Icon name="binoculars" size={2} className="text-white" />
        </Button>

        {/* Кнопка отображает поиск */}
        <Button
          className="btn btn-inline position-relative py-0"
          onClick={() => toggleVisibleMenu('search')}
        >
          <Icon name="search" size={2} className="text-white" />
        </Button>

        {/*
            Кнопка добавления объявления ведущая на страницу подачи объявления
            или вызывающая диалоговое окно, еще не решили.
          */}
        <Link to="#page" className="btn btn-inline position-relative py-0">
          <Icon name="plus-square" size={2} className="text-white" />
        </Link>
      </Col>

      {/* Выезжающий блок главного меню */}
      <Collapse
        show={visibleMenu === 'main'}
        className="col col-12 align-items-start"
      >
        <MobileMainMenu />
      </Collapse>

      {/* Выезжающий блок поиска */}
      <Collapse
        show={visibleMenu === 'search'}
        className="col col-12 align-items-start"
      >
        <MobileSearchForm />
      </Collapse>

      {/* Выезжающий блок Быстрого поиска */}
      <Collapse
        show={visibleMenu === 'searchRequest'}
        className="col col-12 align-items-start"
      >
        <MobileSearchRequestForm />
      </Collapse>
    </Row>
  );
}

export default MobileHeader;
