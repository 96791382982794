import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ThinForm from 'components/SearchBar/ThinForm';
import categoriesStore from 'stores/categories';

class MobileSearchForm extends React.Component {
  state = {
    categories: [],
    query: {},
  };

  syncStore = () => {
    const categories = categoriesStore.store.get().objects;
    this.setState({ categories });
  };

  syncQuery = (cb) => {
    const query = queryString.parse(this.props.location.search);
    this.setState({ query }, cb);
  };

  componentDidMount() {
    categoriesStore.store.subscribe(this, null, this.syncStore);
    categoriesStore.load();
    this.syncQuery(this.syncStore);
  }

  componentWillUnmount() {
    categoriesStore.store.unSubscribe(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.syncQuery();
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const search = {};
    for (let [name, value] of formData) {
      if (value) search[name] = value;
    }
    // При изменении формы номер страницы должен сбросится.
    delete search.p;
    this.props.history.push('/public/search?' + queryString.stringify(search));
  };

  /*
  getCategories = () => {
    return this.state.categories.map(item => (
      <option key={item.id} value={item.id.toString()}>
        {'- '.repeat(item.level)}{item.name}
      </option>
    ))
  }
  */

  render() {
    const { categories, query } = this.state;

    return (
      !!categories.length && (
        <ThinForm
          query={query}
          categories={categories}
          onSubmit={this.onSubmit}
          forMobile
        />
      )
    );
  }
}

export default withRouter(MobileSearchForm);
