import React from 'react';
import PropTypes from 'prop-types';

import Element from './Element';
import { makeid } from './utils';

class Collapse extends React.PureComponent {
  state = {
    id: this.props.id ? this.props.id : makeid('collapse'),
  };

  render() {
    const { id } = this.state;
    const { show, forNavbar, ...props } = this.props;

    const cls = `collapse ${forNavbar ? 'navbar-collapse' : ''} ${
      show ? 'show' : ''
    }`;

    return <Element _className={cls} {...props} id={id} />;
  }
}

Collapse.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  forNavbar: PropTypes.bool,
};

export default Collapse;
