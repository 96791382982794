import React from 'react';

const AuthContext = React.createContext({
  user: {},
  isAuth: false,
  authModalMode: null,
  showAuthModal: () => {},
  next: '',
});

export default AuthContext;
