import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Element from './Element';
import DropdownContext from './DropdownContext';

function hasInline(controlRef) {
  const element = controlRef.current;
  return element ? element.tagName === 'SPAN' : false;
}

function getCtrlSize(controlRef) {
  const element = controlRef.current;
  if (element) {
    // return [ element.clientHeight, element.clientWidth ];
    return [element.offsetHeight, element.offsetWidth];
  }
  return [0, 0];
}

function getStyle(show, direction, correction, controlRef) {
  if (!show) return;

  const [height, width] = getCtrlSize(controlRef);

  const translate = [0, 0];
  if (direction === 'right') {
    translate[0] = width + 2;
  } else if (direction === 'left') {
    translate[0] = -width - 2;
  } else if (direction === 'up') {
    translate[1] = -height - 2;
  } else {
    translate[1] = height + 2;
  }

  translate[0] += correction[0];
  translate[1] += correction[1];

  const inline = hasInline(controlRef);
  const style = {
    position: 'absolute',
    inset:
      direction === 'left'
        ? `0px ${inline ? '0px' : 'auto'} auto auto`
        : direction === 'up'
        ? 'auto auto 0px 0px'
        : '0px auto auto 0px',
    margin: '0px',
    transform: `translate(${translate[0]}px, ${translate[1]}px)`,
  };

  return style;
}

function DropdownMenu({
  controlRef,
  direction = 'down',
  correction = [0, 0],
  ...props
}) {
  const { current } = useContext(DropdownContext);
  const control = controlRef.current?.id;
  const show = control && control === current;
  const style = getStyle(show, direction, correction, controlRef);

  return (
    <Element
      as="div"
      aria-labelledby={control}
      _className={`dropdown-menu ${show ? 'show' : ''}`}
      {...props}
      style={style}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
    />
  );
}

DropdownMenu.propTypes = {
  controlRef: PropTypes.object.isRequired,
  correction: PropTypes.array,
  direction: PropTypes.oneOf(['down', 'up', 'left', 'right']),
};

export default DropdownMenu;
