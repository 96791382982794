import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { makeid } from './utils';
import Element from './Element';

class FormControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  valueFromProps = ({ value, multiple, type }) =>
    type === 'checkbox'
      ? Boolean(value) || false
      : multiple
      ? value || []
      : value === undefined || value === null
      ? ''
      : value;

  getInitialState = () => ({
    // Идентификатор не меняется после монтирования.
    id: this.props.id ? this.props.id : makeid(this.props.idGroup),
    value: this.valueFromProps(this.props),
  });

  propagateValue = (value) => {
    // console.log('propagateValue', this, value);
    const cb = this.props.onChange;
    if (cb) cb(value);
  };

  componentDidMount() {
    const value = this.valueFromProps(this.props);
    this.setState({ value });
  }

  componentDidUpdate(prevProps) {
    const value = this.valueFromProps(this.props);
    const prev = this.valueFromProps(prevProps);
    if (!isEqual(value, prev)) {
      this.setState({ value });
    }
  }

  onChange = (e) => {
    const { multiple, validate } = this.props;
    let value;
    if (multiple) {
      const options = Array.from(e.target.options);
      value = options.filter((i) => i.selected).map((i) => i.value);
    } else {
      value = e.target.value;
    }
    if (validate) {
      value = validate(value, this.state.value);
    }
    this.setState({ value }, () => this.propagateValue(value));
  };

  onChangeCheckbox = (e) => {
    const value = e.currentTarget.checked;
    this.setState({ value }, () => this.propagateValue(value));
  };

  getErrors = () => {
    const { errors } = this.props;
    if (errors && errors.length) {
      return (
        <ul className="text-danger mt-2">
          {errors.map((error, key) => (
            <li key={key}>{error.message}</li>
          ))}
        </ul>
      );
    }
    return <></>;
  };

  getHelp = () => {
    const { help } = this.props;
    if (help) {
      return <small className="form-text text-muted">{help}</small>;
    }
    return <></>;
  };

  renderCheckbox = () => {
    const { id, value } = this.state;
    const {
      label,
      labelClassName,
      labelRef,
      labelElement,
      idGroup,
      type,
      switchMode,
      ...props
    } = this.props;

    delete props.value;
    // delete props.onChange;
    props.checked = value;

    return (
      <>
        <Element
          as="input"
          _className={switchMode ? 'custom-control-input' : 'form-check-input'}
          type="checkbox"
          {...props}
          onChange={this.onChangeCheckbox}
          id={id}
        />
        {label && (
          <Element
            as={labelElement}
            _className={
              switchMode ? 'custom-control-label' : 'form-check-label'
            }
            className={labelClassName}
            htmlFor={id}
            elementRef={labelRef}
          >
            {label}
          </Element>
        )}
      </>
    );
  };

  renderInput = () => {
    const { id, value } = this.state;
    const {
      label,
      labelClassName,
      labelRef,
      labelElement,
      idGroup,
      validate,
      ...props
    } = this.props;

    // delete props.value;
    // delete props.onChange;

    return (
      <>
        {label && (
          <Element
            as={labelElement}
            className={labelClassName}
            htmlFor={id}
            elementRef={labelRef}
          >
            {label}
          </Element>
        )}
        <Element
          as="input"
          _className="form-control"
          {...props}
          value={value}
          onChange={this.onChange}
          id={id}
        />
      </>
    );
  };

  renderSelect = () => {
    const { id, value } = this.state;
    const { label, labelClassName, labelRef, labelElement, idGroup, ...props } =
      this.props;

    return (
      <>
        {label && (
          <Element
            as={labelElement}
            className={labelClassName}
            htmlFor={id}
            elementRef={labelRef}
          >
            {label}
          </Element>
        )}
        <Element
          as="select"
          _className="form-control"
          {...props}
          value={value}
          onChange={this.onChange}
          id={id}
        />
        {this.getErrors()}
        {this.getHelp()}
      </>
    );
  };

  render() {
    const { type } = this.props;

    let component;

    if (type === 'checkbox') {
      component = this.renderCheckbox();
    } else if (type === 'select') {
      component = this.renderSelect();
    } else {
      component = this.renderInput();
    }

    return (
      <>
        {component}
        {this.getErrors()}
        {this.getHelp()}
      </>
    );
  }
}

FormControl.propTypes = {
  id: PropTypes.string,
  idGroup: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  help: PropTypes.node,
  type: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelRef: PropTypes.object,
  labelElement: PropTypes.string,
  validate: PropTypes.func,
};

FormControl.defaultProps = {
  type: 'text',
  idGroup: 'input',
  labelElement: 'label',
};

export default FormControl;
