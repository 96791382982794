// import moment from "moment";
// import 'moment/min/locales';
import 'moment/locale/ru';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import { user } from 'stores';
// import reportWebVitals from 'reportWebVitals';

// Устанавливаем язык определённый сервером для этого документа.
// moment.updateLocale(document.documentElement.lang, {});

// Функция запуска приложения React.
function runApp() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals(console.log);
}

// Запуск приложения осуществляется только после первичного получения
// информации о пользователе.
user.auth(runApp);
