import React from 'react';
import { NavLink } from 'react-router-dom';

import AuthContext from './AuthContext';

function AuthLink({ to, menu, ...props }) {
  const { isAuth, showAuthModal } = React.useContext(AuthContext);

  const extra = {};
  if (!isAuth) {
    extra.onClick = (e) => {
      e.preventDefault();
      showAuthModal('login');
    };
  }

  return <NavLink to={to} {...props} {...extra} />;
}

export default AuthLink;
