import React from 'react';
import PropTypes from 'prop-types';

import Element from './Element';

export default function Container({ fluid = false, ...props }) {
  let cls = 'container';
  switch (fluid) {
    case true:
      cls += '-fluid';
      break;
    case 'sm':
    case 'md':
    case 'lg':
    case 'xl':
      cls += `-${fluid}`;
      break;
    default:
      break;
  }

  return <Element _className={cls} {...props} />;
}

Container.propTypes = {
  fluid: PropTypes.bool,
};
