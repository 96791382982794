/*
 * Модель локаций.
 *
 */
import Store from 'utils/store';
import { makeGET } from 'utils/requests';

const store = new Store({
  objects: [],
  isLoaded: false,
});

function load(force, cb) {
  if (force || !store.get().isLoaded) {
    makeGET({
      path: '/public/locations/',
    }).then((response) => {
      if (response.error) {
        if (cb) cb(response);
      } else {
        store.update({ isLoaded: true, objects: response.data });
        if (cb) cb(response);
      }
    });
  }
}

function getLocation(id) {
  const data = store.get().objects;
  const ids = data.map((item) => item.id);
  return data[ids.indexOf(id)];
}

const locations = {
  store,
  load,
  needToGet: () => store.update({ isLoaded: false }),
  getLocation,
};

export default locations;
